/* eslint-disable react-hooks/exhaustive-deps */
import { Interface } from '@ethersproject/abi'
import { Currency, Pair, TokenAmount } from '@stoboxpswap/sdk'
import IPancakePairABI from 'config/abi/IPancakePair.json'
import { isSecurityToken } from 'config/constants/tokens'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useMemo } from 'react'
import { feeContent } from 'state/swap/hooks'
import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const PAIR_INTERFACE = new Interface(IPancakePairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function usePairs(
  currencies: [Currency | undefined, Currency | undefined][],
  feeInfo?: { [key: string]: number } | null,
): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()
  const feeValue = feeContent?.reduce((acc, item) => {
    if (feeInfo && feeInfo[item]) {
      // eslint-disable-next-line no-param-reassign
      acc += feeInfo[item]
    }
    return acc
  }, 0)
  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies, feeInfo],
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`,
          )

          return undefined
        }
      }),
    [tokens],
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { _reserve0, _reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]

      const hasSecurityToken = isSecurityToken(tokenA.address) || isSecurityToken(tokenB.address)

      const feePairValue = hasSecurityToken ? 0 : feeValue ? +feeValue?.toFixed(1) : 0

      return [
        PairState.EXISTS,
        new Pair(
          new TokenAmount(token0, _reserve0.toString()),
          new TokenAmount(token1, _reserve1.toString()),
          feePairValue,
        ),
      ]
    })
  }, [results, tokens, feeInfo, feeValue])
}

export function usePair(
  tokenA?: Currency,
  tokenB?: Currency,
  feeInfo?: { [key: string]: number } | null,
): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]], feeInfo)[0]
}
