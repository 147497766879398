function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  padding: 0;\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { variant } from "styled-system";
import Button from "./Button";
import { IconButtonScaleVariants } from "./theme";
var IconButton = styled(Button).withConfig({
    componentId: "sc-887c3746-0"
})(_templateObject(), variant({
    prop: "scale",
    variants: IconButtonScaleVariants
}));
export default IconButton;
