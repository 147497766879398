import { ChainId, Token } from '@stoboxpswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

export const restrictedTokens = [
  '0xe2242025a8c087b2c1c879504fdcc109d69a85af',
  '0x483fe55d2a67c12524e1e4c9c7b8c5139139acc0',
  '0x54ab56671402281f216d6cd3f38b5c93ef5a5150',
  '0x9d986a3f147212327dd658f712d5264a73a1fdb0',
  '0xaa6d73c22a953a6a83b9963052ba73f0c53fc764',
]

const defineTokens = <T extends TokenList>(t: T) => t

export const ecosystemTokens: string[] = [
  '0x54aB56671402281F216d6CD3f38b5C93ef5a5150',
  // SLX Token
  // '0x2C4A911B16435f96e6bD18E4d720a32480554a22',
]

export const securityMainnetTokens: TokenList = defineTokens({
  skst: new Token(
    MAINNET,
    '0xaa6d73c22a953a6a83b9963052ba73f0c53fc764',
    18,
    'SKST',
    'Skyline Security Token',
    'https://www.binance.com/',
  ),
  // slx: new Token(
  //   MAINNET,
  //   '0x2C4A911B16435f96e6bD18E4d720a32480554a22',
  //   0,
  //   'SLX',
  //   'SLX Revenue Share Security Token',
  //   'https://assets.stobox.io/auth/login',
  // ),
  land: new Token(
    MAINNET,
    '0xAC9611232704A38354858a8FBa4624a0b01987fB',
    0,
    'LS81712NF',
    'Landshare 817 12th Ave N Fargo Security Token',
    'https://www.landshare.io/',
  ),
  lsrwa: new Token(
    MAINNET,
    '0x475eD67Bfc62B41c048b81310337c1D75D45aADd',
    0,
    'LSRWA',
    'Landshare RWA Token',
    'https://www.landshare.io/',
  ),
})

export const isSecurityToken = (tokenAddress: string) => {
  return getSecurityTokenItem(tokenAddress) !== undefined
}

export const isEcosystemToken = (tokenAddress: string) => {
  let _isEcosystemToken = false
  ecosystemTokens.forEach((ecosystemToken) => {
    if (ecosystemToken.toLowerCase() === tokenAddress.toLowerCase()) {
      _isEcosystemToken = true
    }
  })

  return _isEcosystemToken
}

export const getSecurityTokenItem = (tokenAddress: string) => {
  const values = Object.values(securityMainnetTokens)
  for (let index = 0; index < values.length; index++) {
    const token = values[index]
    if (token?.address.toLowerCase() === tokenAddress?.toLowerCase()) return token
  }
  return undefined
}

export const mainnetTokens = defineTokens({
  ...securityMainnetTokens,
  wbnb: new Token(
    MAINNET,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'BNB', 'BNB', 'https://www.binance.com/'),
  cake: new Token(
    MAINNET,
    '0xb0c4080a8Fa7afa11a09473f3be14d44AF3f8743',
    18,
    'STBU',
    'Stobox Token v.2',
    'https://stobox.io/',
  ),
  land: new Token(
    MAINNET,
    '0xa73164db271931cf952cbaeff9e8f5817b42fa5c',
    18,
    'LAND',
    'Landshare Token',
    'https://landshare.io/',
  ),
  $soulb: new Token(
    MAINNET,
    '0xddd394707fc1af81d98f132825df713dd9bb494e',
    18,
    '$SOULB',
    'SOULB ID TOKEN',
    'https://sbid.io/',
  ),
  usdt: new Token(
    MAINNET,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Tether USDT',
    'https://www.binance.com/en/trade/BNB_USDT',
  ),
  busd: new Token(
    MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  skst: new Token(
    MAINNET,
    '0xaa6d73c22a953a6a83b9963052ba73f0c53fc764',
    18,
    'SKST',
    'Skyline Security Token',
    'https://www.binance.com/',
  ),
  // slx: new Token(
  //   MAINNET,
  //   '0x2C4A911B16435f96e6bD18E4d720a32480554a22',
  //   0,
  //   'SLX',
  //   'SLX Revenue Share Security Token',
  //   'https://assets.stobox.io/auth/login',
  // ),
  usdc: new Token(
    MAINNET,
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    18,
    'USDC',
    'USD Coin',
    'https://www.binance.com/',
  ),
  lsrwa: new Token(
    MAINNET,
    '0x475eD67Bfc62B41c048b81310337c1D75D45aADd',
    0,
    'LSRWA',
    'Landshare RWA Token',
    'https://www.landshare.io/',
  ),
} as const)

export const testnetTokens = defineTokens({
  wbnb: new Token(
    TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  cake: new Token(
    TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'Stobox Token',
    'https://pancakeswap.finance/',
  ),
  busd: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  syrup: new Token(
    TESTNET,
    '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    18,
    'SYRUP',
    'SyrupBar Token',
    'https://pancakeswap.finance/',
  ),
  bake: new Token(
    TESTNET,
    '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    18,
    'BAKE',
    'Bakeryswap Token',
    'https://www.bakeryswap.org/',
  ),
} as const)

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens
}

export default unserializedTokens
