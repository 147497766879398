import { Flex, IconButton, CogIcon, useModal } from '@pancakeswap/uikit'
import styled from 'styled-components'
import SettingsModal from './SettingsModal'

const Separator = styled.div`
  align-self: center;
  height: 24px;
  width: 1px;
  margin-left: 16px;
  background: ${({ theme }) => theme.colors.cardBorder};
`

type Props = {
  color?: string
  mr?: string
  ml?: string
}

const GlobalSettings = ({ color, mr = '8px', ml = '8px' }: Props) => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex ml={ml} mr={mr}>
      <IconButton
        onClick={onPresentSettingsModal}
        padding="9px"
        variant="card"
        scale="sm2"
        id="open-settings-dialog-button"
      >
        <CogIcon height={24} width={24} color={color || 'textDescription'} />
      </IconButton>
      <Separator />
    </Flex>
  )
}

export default GlobalSettings
