function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { scales } from "./types";
var _obj;
export var scaleVariants = (_obj = {}, _defineProperty(_obj, scales.MD, {
    fontSize: "16px",
    lineHeight: "24px"
}), _defineProperty(_obj, scales.SM, {
    fontSize: "14px",
    lineHeight: "20px"
}), _defineProperty(_obj, scales.XS, {
    fontSize: "12px"
}), _obj);
