import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Menu as UikitMenu, Network } from '@pancakeswap/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePhishingBannerManager } from 'state/user/hooks'
import usePriceOracle from 'hooks/usePriceOracle'
import { DEFAULT_INPUT_CURRENCY, NOT_ON_SALE_SELLER } from 'config/constants'
import { USD_ISO4217 } from 'config/constants/fiat'
import UserMenu from './UserMenu'
import { useMenuItems } from './hooks/useMenuItems'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'

const Image = styled.img`
  width: 24px;
  height: 24px;
`

const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const cakePriceUsd = usePriceOracle(USD_ISO4217, DEFAULT_INPUT_CURRENCY, NOT_ON_SALE_SELLER)
  const { currentLanguage, setLanguage, t } = useTranslation()
  const [network, setNetwork] = useState<Network>({
    name: 'BSC',
    value: 'BSC',
    icon: <Image src="/images/wallets/wallet-BNB.svg" alt="BNB" />,
  })
  const { pathname } = useRouter()
  const [showPhishingWarningBanner] = usePhishingBannerManager()

  const menuItems = useMenuItems()

  const networksList = [
    {
      name: 'Ethereum',
      value: 'Ethereum',
      icon: <Image src="/images/wallets/wallet-ETH.svg" alt="ETH" />,
      disabled: true,
    },
    {
      name: 'Polygon',
      value: 'Polygon',
      icon: <Image src="/images/wallets/wallet-MATIC.svg" alt="MATIC" />,
      disabled: true,
    },
    {
      name: 'BSC',
      value: 'BSC',
      icon: <Image src="/images/wallets/wallet-BNB.svg" alt="BNB" />,
    },
  ]

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  return (
    <UikitMenu
      linkComponent={(linkProps) => {
        return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
      }}
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings mr="16px" ml="8px" />}
      // banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      currentNetwork={network}
      networks={networksList}
      langs={languageList}
      setLang={setLanguage}
      setNetwork={(data: Network) => setNetwork(data)}
      cakePriceUsd={cakePriceUsd}
      links={menuItems}
      subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={footerLinks(t)}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyCakeLabel={t('Buy CAKE')}
      {...props}
    />
  )
}

export default Menu
