import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Stobox DS Swap | Multi-chain swap for security tokens and tokenized securities',
  description:
    'DS Swap allows you to create a liquidity pool and enable for investors to buy and sell tokenized shares on private venue with ease',
  image: 'https://app.fat.dsswap.io/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  const rightText = t('Multi-chain swap for security tokens and tokenized securities')

  switch (basePath) {
    case '/':
      return {
        title: `${t('Stobox')} ${t('Home')} | ${rightText}`,
      }
    case '/swap':
      return {
        title: `${t('Stobox')} Swap | ${rightText}`,
      }
    case '/add':
      return {
        title: `${t('Stobox')} ${t('Add Liquidity')} | ${rightText}`,
      }
    case '/remove':
      return {
        title: `${t('Stobox')} ${t('Remove Liquidity')} | ${rightText}`,
      }
    case '/liquidity':
      return {
        title: `${t('Stobox')} ${t('Liquidity')} | ${rightText}`,
      }
    case '/find':
      return {
        title: `${t('Stobox')} ${t('Import Pool')} | ${rightText}`,
      }
    case '/competition':
      return {
        title: `${t('Stobox')} ${t('Trading Battle')} | ${rightText}`,
      }
    case '/prediction':
      return {
        title: `${t('Stobox')} ${t('Prediction')} | ${rightText}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Stobox')} ${t('Leaderboard')} | ${rightText}`,
      }
    case '/farms':
      return {
        title: `${t('Stobox')} ${t('Farms')} | ${rightText}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Stobox')} ${t('Farm Auctions')} | ${rightText}`,
      }
    case '/pools':
      return {
        title: `${t('Stobox')} ${t('Pools')} | ${rightText}`,
      }
    case '/lottery':
      return {
        title: `${t('Stobox')} ${t('Lottery')} | ${rightText}`,
      }
    case '/ifo':
      return {
        title: `${t('Stobox')} ${t('Initial Farm Offering')} | ${rightText}`,
      }
    case '/teams':
      return {
        title: `${t('Stobox')} ${t('Leaderboard')} | ${rightText}`,
      }
    case '/voting':
      return {
        title: `${t('Stobox')} ${t('Voting')} | ${rightText}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Stobox')} ${t('Proposals')} | ${rightText}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Stobox')} ${t('Make a Proposal')} | ${rightText}`,
      }
    case '/info':
      return {
        // title: `${t('Overview')} | ${t('Stobox Info & Analytics')}`
        title: `${t('Stobox')} ${t('Overview')} | ${rightText}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/pools':
      return {
        // title: `${t('Pools')} | ${t('Stobox Info & Analytics')}`
        title: `${t('Stobox')} ${t('Pools')} | ${rightText}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/tokens':
      return {
        // title: `${t('Tokens')} | ${t('Stobox Info & Analytics')}`
        title: `${t('Stobox')} ${t('Tokens')} | ${rightText}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Stobox')} ${t('Overview')} | ${rightText}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Stobox')} ${t('Collections')} | ${rightText}`,
      }
    case '/nfts/activity':
      return {
        title: `${t('Stobox')} ${t('Activity')} | ${rightText}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Stobox')} ${t('Profile')} | ${rightText}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Stobox')} ${t('Pancake Squad')} | ${rightText}`,
      }
    default:
      return null
  }
}
