import { formatEther } from '@ethersproject/units'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useMemo } from 'react'
import { usePriceOracleContract } from './useContract'

/**
 *
 * @param {number} fiatISOCode ISO_4217 fiat code
 * @param {string} targetCoin address of target coin
 * @param {string} relatedCoin
 * @returns {string|undefined} Coin price
 */
export default function usePriceOracle(fiatISOCode: number, targetCoin: string, relatedCoin: string) {
  const priceContract = usePriceOracleContract()

  /**
   * Returns array response
   * [0] { boolean } indicates pair exist
   * [1] { BigNumber } coin price
   * */
  const contractResponse = useSingleCallResult(priceContract, 'getCoinPrice', [
    fiatISOCode,
    targetCoin,
    relatedCoin,
  ])?.result

  return useMemo(() => {
    // Check response complete and pair existance
    if (contractResponse && contractResponse[0] === true) {
      // Get actual price in Wei
      const priceBN = contractResponse[1]
      if (priceBN) {
        const priceEther = formatEther(priceBN)

        const actualPrice = Number(priceEther).toFixed(6)

        return actualPrice
      }
    }

    return undefined
  }, [contractResponse])
}
