import Metamask from "../../components/Svg/Icons/Metamask";
import { ConnectorNames } from "./types";
var connectors = [
    {
        title: "Metamask",
        icon: Metamask,
        connectorId: ConnectorNames.Injected,
        priority: 1,
        href: "https://metamask.app.link/dapp/".concat(process.env.NEXT_PUBLIC_DOMAIN)
    },
    // {
    //   title: "Binance Wallet",
    //   icon: BinanceChain,
    //   connectorId: ConnectorNames.BSC,
    //   priority: 2,
    // },
    // {
    //   title: "Coinbase Wallet",
    //   icon: CoinbaseWallet,
    //   connectorId: ConnectorNames.WalletLink,
    //   priority: 3,
    // },
    // {
    //   title: "Trust Wallet",
    //   icon: TrustWallet,
    //   connectorId: ConnectorNames.Injected,
    //   priority: 4,
    //   href: "https://link.trustwallet.com/open_url?coin_id=20000714&url=http://pancakeswap.finance/",
    // },
    // {
    //   title: "WalletConnect",
    //   icon: WalletConnect,
    //   connectorId: ConnectorNames.WalletConnect,
    //   priority: 5,
    // },
    // {
    //   title: "Opera",
    //   icon: Opera,
    //   connectorId: ConnectorNames.Injected,
    //   priority: () => {
    //     return typeof window !== "undefined" && Boolean(window?.ethereum?.isOpera) ? 0 : 6;
    //   },
    //   href: "https://www.opera.com/crypto/next",
    // },
    // {
    //   title: "MathWallet",
    //   icon: MathWallet,
    //   connectorId: ConnectorNames.Injected,
    //   priority: 999,
    // },
    // {
    //   title: "TokenPocket",
    //   icon: TokenPocket,
    //   connectorId: ConnectorNames.Injected,
    //   priority: 999,
    // },
    // {
    //   title: "SafePal",
    //   icon: SafePal,
    //   connectorId: ConnectorNames.Injected,
    //   priority: 999,
    // },
    // {
    //   title: "Coin98",
    //   icon: Coin98,
    //   connectorId: ConnectorNames.Injected,
    //   priority: 999,
    // },
    // {
    //   title: "Blocto",
    //   icon: Blocto,
    //   connectorId: ConnectorNames.Blocto,
    //   priority: 999,
    // },
    {
        title: "Stobox",
        icon: Metamask,
        connectorId: ConnectorNames.StoboxSSO,
        priority: 999,
        href: "https://metamask.app.link/dapp/".concat(process.env.NEXT_PUBLIC_DOMAIN)
    }, 
];
export default connectors;
export var connectorLocalStorageKey = "connectorIdv2";
export var walletLocalStorageKey = "wallet";
export var walletConnectConfig = connectors.find(function(c) {
    return c.title === "WalletConnect";
});
