import { Button, ButtonProps, ConnectorNames } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'

import { useEffect } from 'react'
import Trans from './Trans'

const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { login } = useAuth()

  const onClick = () => {
    window?.dataLayer?.push({
      event: 'connect_wallet',
    })
    login(ConnectorNames.StoboxSSO)
  }

  useEffect(() => {
    if (localStorage.getItem('authMethod')) {
      login(ConnectorNames.StoboxSSO)
    }
  }, [login])

  return (
    <Button onClick={onClick} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </Button>
  )
}

export default ConnectWalletButton
