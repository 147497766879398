function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _objectSpread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _defineProperty(target, key, source[key]);
        });
    }
    return target;
}
export var baseColors = {
    failure: "#D54238",
    primary: "#2F4F92",
    primaryBright: "#3b63b4",
    primaryDark: "#2f4e91",
    secondary: "#46B9FF",
    success: "#5FCD6B",
    warning: "#FFA70F"
};
export var additionalColors = {
    binance: "#F0B90B",
    overlay: "#452a7a",
    gold: "#FFC700",
    silver: "#B2B2B2",
    bronze: "#E7974D"
};
export var lightColors = _objectSpread({}, baseColors, additionalColors, {
    background: "#F7F8FA",
    backgroundDisabled: "#f6f8fc",
    backgroundAlt: "#FFFFFF",
    backgroundAlt2: "rgba(255, 255, 255, 0.7)",
    cardBorder: "#DFE2E5",
    contrast: "#191326",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EEEEEE",
    invertedContrast: "#FFFFFF",
    input: "#eaf1f4",
    inputSecondary: "#eaeff4",
    tertiary: "#EFF4F5",
    text: "#20242E",
    textDisabled: "#BDC2C4",
    textSubtle: "#526575",
    textDescription: "#979FA8",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        cardHeader: "linear-gradient(111.68deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        blue: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        violet: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        violetAlt: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        gold: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)"
    }
});
export var darkColors = _objectSpread({}, baseColors, additionalColors, {
    secondary: "#9A6AFF",
    background: "#08060B",
    backgroundDisabled: "#3c3742",
    backgroundAlt: "#27262c",
    backgroundAlt2: "rgba(39, 38, 44, 0.7)",
    cardBorder: "#383241",
    contrast: "#FFFFFF",
    dropdown: "#1E1D20",
    dropdownDeep: "#100C18",
    invertedContrast: "#191326",
    input: "#372F47",
    inputSecondary: "#262130",
    primaryDark: "#0098A1",
    tertiary: "#353547",
    text: "#F4EEFF",
    textDisabled: "#666171",
    textSubtle: "#ffffff",
    disabled: "#524B63",
    textDescription: "#979FA8",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        inverseBubblegum: "linear-gradient(139.73deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        cardHeader: "linear-gradient(111.68deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        blue: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        violet: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        violetAlt: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)",
        gold: "linear-gradient(180deg, rgba(70,185,255, 0.1) 0%, rgba(47, 79, 146, 0.1) 100%)"
    }
});
