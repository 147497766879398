function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  border: 1px solid ",
        ";\n  box-shadow: 0px 8px 40px rgba(32, 36, 46, 0.12);\n  background: #fff;\n  border-radius: 8px;\n  pointer-events: auto;\n  width: 248px;\n  max-height: 300px;\n  overflow-y: auto;\n  visibility: visible;\n  z-index: 1001;\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  display: grid;\n  grid-template-columns: auto 1fr auto;\n  text-align: left;\n  column-gap: 16px;\n  border: 0;\n  background: transparent;\n  color: ",
        ";\n  cursor: ",
        ";\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  min-height: 48px;\n  border-bottom: 1px solid ",
        ";\n  outline: 0;\n  padding: 12px 16px;\n  width: 100%;\n\n  &:hover:not(:disabled) {\n    background-color: #fafafb;\n  }\n\n  &:active:not(:disabled) {\n    opacity: 0.85;\n    transform: translateY(1px);\n  }\n\n  &:last-child {\n    border: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n  align-items: center;\n  fill: ",
        ";\n  width: 8px;\n  transform: ",
        ";\n  transition: fill 0.2s;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n  display: grid;\n  grid-template-columns: auto 1fr auto;\n  border: 1px solid ",
        ";\n  box-shadow: 0px 1px 1px rgba(32, 36, 46, 0.12);\n  border-radius: 6px;\n  min-width: 156px;\n  padding: 7px 16px;\n  font-size: 12px;\n  font-weight: 600;\n  align-items: center;\n  transition: background-color 0.2s, opacity 0.2s, border 0.2s, color 0.2s;\n  column-gap: 8px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n  align-items: center;\n  height: 56px;\n  cursor: pointer;\n  display: inline-flex;\n  position: relative;\n\n  &:hover ",
        ", &:focus-within ",
        " {\n    border: 1px solid ",
        ";\n  }\n\n  &:hover ",
        " {\n    fill: ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n  align-self: center;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { Flex } from "../Box";
import { ArrowUpIcon } from "../Svg";
export var Menu = styled.div.withConfig({
    componentId: "sc-9cf313d2-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
}, function(param) {
    var isOpen = param.isOpen;
    return !isOpen && "\n    pointer-events: none;\n    visibility: hidden;\n  ";
});
export var MenuItem = styled.button.withConfig({
    componentId: "sc-9cf313d2-1"
})(_templateObject1(), function(param) {
    var theme = param.theme, disabled = param.disabled;
    return theme.colors[disabled ? "textDisabled" : "text"];
}, function(param) {
    var disabled = param.disabled;
    return disabled ? "not-allowed" : "pointer";
}, function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
});
export var ArrowIcon = styled(ArrowUpIcon).withConfig({
    componentId: "sc-9cf313d2-2"
})(_templateObject2(), function(param) {
    var show = param.show, theme = param.theme;
    return show ? theme.colors.secondary : "currentColor";
}, function(param) {
    var show = param.show;
    return "rotate(".concat(show ? 180 : 0, "deg)");
});
export var DropDown = styled(Flex).withConfig({
    componentId: "sc-9cf313d2-3"
})(_templateObject3(), function(param) {
    var theme = param.theme;
    return theme.colors.tertiary;
});
export var Selector = styled(Flex).withConfig({
    componentId: "sc-9cf313d2-4"
})(_templateObject4(), DropDown, Menu, function(param) {
    var theme = param.theme;
    return theme.colors.secondary;
}, ArrowIcon, function(param) {
    var theme = param.theme;
    return theme.colors.secondary;
});
export var Span = styled.span.withConfig({
    componentId: "sc-9cf313d2-5"
})(_templateObject5());
