function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  color: ",
        ";\n  font-weight: ",
        ";\n  ",
        "\n  ",
        "\n\n  ",
        "\n  ",
        "\n  ",
        "\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { space, typography, layout, variant } from "styled-system";
import getThemeValue from "../../util/getThemeValue";
import { scaleVariants } from "./theme";
var getColor = function(param) {
    var color = param.color, theme = param.theme;
    return getThemeValue("colors.".concat(color), color)(theme);
};
var Text = styled.div.withConfig({
    componentId: "sc-c6692a9-0"
})(_templateObject(), getColor, function(param) {
    var bold = param.bold;
    return bold ? 600 : 400;
}, function(param) {
    var textTransform = param.textTransform;
    return textTransform && "text-transform: ".concat(textTransform, ";");
}, function(param) {
    var ellipsis = param.ellipsis;
    return ellipsis && "white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;";
}, space, typography, layout, variant({
    prop: "scale",
    variants: scaleVariants
}));
Text.defaultProps = {
    color: "text",
    scale: "md",
    ellipsis: false
};
export default Text;
